.App {
  main {
    text-align: center;
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
  &-Form-Wrapper {
    min-height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &-Logo {
    width: 50%;
    margin: 2% 0;
    height: auto;
  }
  &-Form {
    background-color: inherit;
    border-radius: 32px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
    width: 100%;
    max-width: 850px;
    padding: 1rem;
  }
  &-Page-Wrapper {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
  }
}

// animations

@keyframes bounce-in {
  0% {
    animation-timing-function: cubic-bezier(0.0383, 0.3388, 0.0421, 0.6652);
    transform: matrix(0.01, 0, 0, 0.01, 0, 0);
  }
  4.7% {
    animation-timing-function: cubic-bezier(0.3296, 0.3336, 0.5772, 0.6672);
    transform: matrix(0.556, 0, 0, 0.556, 0, 0);
  }
  8.4% {
    animation-timing-function: cubic-bezier(0.0781, 0.1617, 0.0874, 0.9301);
    transform: matrix(0.979, 0, 0, 0.979, 0, 0);
  }
  12.4% {
    animation-timing-function: cubic-bezier(0.8341, 0.1496, 0.8634, 0.7673);
    transform: matrix(1.153, 0, 0, 1.153, 0, 0);
  }
  16.3% {
    animation-timing-function: cubic-bezier(0.3264, 0.3332, 0.5758, 0.6695);
    transform: matrix(1.008, 0, 0, 1.008, 0, 0);
  }
  21.2% {
    animation-timing-function: cubic-bezier(0.0921, 0.1883, 0.3277, 1);
    transform: matrix(0.704, 0, 0, 0.704, 0, 0);
  }
  24.5% {
    animation-timing-function: cubic-bezier(0.6905, 0.0944, 0.8759, 0.7624);
    transform: matrix(0.626, 0, 0, 0.626, 0, 0);
  }
  27.7% {
    animation-timing-function: cubic-bezier(0.3688, 0.332, 0.624, 0.6684);
    transform: matrix(0.704, 0, 0, 0.704, 0, 0);
  }
  32.6% {
    animation-timing-function: cubic-bezier(0.1368, 0.2364, 0.2451, 0.9049);
    transform: matrix(0.958, 0, 0, 0.958, 0, 0);
  }
  37.4% {
    animation-timing-function: cubic-bezier(0.5936, 0.0846, 0.3495, 1);
    transform: matrix(1.085, 0, 0, 1.085, 0, 0);
  }
  49.5% {
    animation-timing-function: cubic-bezier(0.5522, 0.0981, 0.3807, 1);
    transform: matrix(0.802, 0, 0, 0.802, 0, 0);
  }
  62.4% {
    animation-timing-function: cubic-bezier(0.4497, 0.1349, 0.4911, 1);
    transform: matrix(1.044, 0, 0, 1.044, 0, 0);
  }
  74.1% {
    animation-timing-function: cubic-bezier(0.429, 0, 0.5239, 1);
    transform: matrix(0.914, 0, 0, 0.914, 0, 0);
  }
  87% {
    animation-timing-function: cubic-bezier(0.3501, 0, 0.6422, 1);
    transform: matrix(1.013, 0, 0, 1.013, 0, 0);
  }
  95.8% {
    animation-timing-function: cubic-bezier(0.3653, 0, 0.6776, 1);
    transform: matrix(0.992, 0, 0, 0.992, 0, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

.grey-text {
  color: #9e9e9e;
}

@keyframes swim {
  0% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
  8.33333% {
    transform: translate(-5.964616450431025px, 4.908451131175324px)
      rotate(-13.668208599899142deg) scale(1);
    animation-timing-function: linear;
  }
  16.66667% {
    transform: translate(5.25471039505817px, -2.0560551770833544px)
      rotate(0.47337246955805057deg) scale(1);
    animation-timing-function: linear;
  }
  25% {
    transform: translate(2.3092945832820213px, 5.79372296066372px)
      rotate(13.856398766957234deg) scale(1);
    animation-timing-function: linear;
  }
  33.33333% {
    transform: translate(-5.755561439891309px, -4.608015625430103px)
      rotate(10.942457102398254deg) scale(1);
    animation-timing-function: linear;
  }
  41.66667% {
    transform: translate(3.735215864663777px, 5.97741817490823px)
      rotate(-14.030785412713554deg) scale(1);
    animation-timing-function: linear;
  }
  50% {
    transform: translate(4.262798960985801px, -3.012224770398824px)
      rotate(-10.613227721848103deg) scale(1);
    animation-timing-function: linear;
  }
  58.33333% {
    transform: translate(-4.659754200309688px, -2.512690726101111px)
      rotate(5.286897990634351deg) scale(1);
    animation-timing-function: linear;
  }
  66.66667% {
    transform: translate(1.640244960302045px, -1.0516734570451254px)
      rotate(10.273425806369517deg) scale(1);
    animation-timing-function: linear;
  }
  75% {
    transform: translate(5.559539091439522px, -4.22763235045797px)
      rotate(-5.727258555619329deg) scale(1);
    animation-timing-function: linear;
  }
  83.33333% {
    transform: translate(-2.8460205249047514px, 5.914393016179435px)
      rotate(-14.991925396002646deg) scale(1);
    animation-timing-function: linear;
  }
  91.66667% {
    transform: translate(-0.7074387276019625px, -5.430640274284412px)
      rotate(6.161915473106543deg) scale(1);
    animation-timing-function: linear;
  }
  100% {
    transform: translate(0, 0) rotate(0) scale(1);
  }
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes dashCheck {
  from {
    stroke-dashoffset: -100;
  }
  to {
    stroke-dashoffset: 900;
  }
}

.swim-animation {
  animation-name: swim;
  animation-duration: 7.69231s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.bounce-in-animation {
  animation-name: bounce-in;
  animation-duration: 0.990099s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.circle-animation {
  fill: none;
  stroke: #1976d2;
  stroke-width: 9;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation-name: dash;
  animation-duration: 0.9s;
  animation-delay: 0.35s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: initial;
}

.check-animation {
  fill: none;
  stroke: #1976d2;
  stroke-width: 12;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  stroke-dasharray: 1000;
  stroke-dashoffset: -100;
  animation-name: dashCheck;
  animation-duration: 0.9s;
  animation-delay: 0.55s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: initial;
}

.TEstCss {
  color: red;
}
